<template>
    <div class="form" :class="state">

        <label for="name">{{ dictionary.name }}</label>
        <input type="text" id="name" name="name" v-model="name" :placeholder="dictionary.name_placeholder">

        <label for="business">{{ dictionary.business }}</label>
        <input type="text" id="business" name="business" v-model="business"  :placeholder="dictionary.business_placeholder">

        <label for="contact">{{ dictionary.contact }}</label>
        <input type="text" id="contact" name="contact" v-model="contact" :placeholder="dictionary.contact_placeholder">

        <div>{{ dictionary.terms }}</div>

        <div class="submit">{{ dictionary.submit }}</div>
    </div>
</template>
<script>
export default {
    props: {
        dictionary: { type: Object, required: true }
    },
    data(){
        return {
            state: null,
            options: false,
            name: '',
            business: '',
            contact: ''
        }
    },
    methods: {
        submit(){
            this.state = 'loading';

            setTimeout(() => {
                this.state = 'thank-you';
            }, 5000);
        }
    }
}
</script>
