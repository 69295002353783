<template>
    <div class="components">
        <div class="group">
            <div class="component status" data-icon="notifications_none">{{ notifications }} {{ dictionary.notifications }}</div>
            <div class="component sum">
                <div data-icon="verified">{{ new_orders }} {{ dictionary.new_orders }}</div>
                <div data-icon="mark_chat_unread">{{ new_messages }} {{ dictionary.new_messages }}</div>
                <div data-icon="production_quantity_limits">{{ checkouts }} {{ dictionary.checkouts }}</div>
                <div data-icon="published_with_changes">{{ modifications }} {{ dictionary.modifications }}</div>
            </div>
            <div class="component revenue">
                <span>{{ dictionary.revenue }}</span>
                <span>{{ formatNumber(revenue)}}</span>
            </div>
            <div class="component chat">
                <div>!@#$%^&*()!@#$%^&*()</div>
                <div>!@#$%^&*()</div>
                <div class="message">{{ dictionary.chat_client }}</div>
                <div class="message">{{ dictionary.chat_support }} 😄</div>
            </div>
            <div class="component status" data-icon="wifi_tethering">{{ active_carts }} {{ dictionary.active_carts }}</div>
        </div>
        <div class="group">
            <div class="component bars">
                <div v-for="m in dictionary.month" :key="m">{{m}}</div>
            </div>
            <div class="component payments">
                <svg viewBox="0 0 42 42">
                    <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>

                    <circle class="a" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="3"></circle>
                    <circle class="b" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="3" stroke-dasharray="40 60" stroke-dashoffset="25"></circle>
                    <circle class="c" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="3" stroke-dasharray="20 80" stroke-dashoffset="85"></circle>
                    <circle class="d" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="3" stroke-dasharray="30 70" stroke-dashoffset="65"></circle>
                </svg>

                <div class="data">
                    <div>
                        <span>{{ dictionary.payment_credit_card }}</span>
                        <span>{{ formatNumber(payment_credit_card) }}</span>
                    </div>
                    <div>
                        <span>{{ dictionary.payment_apple_pay }}</span>
                        <span>{{ formatNumber(payment_apple_pay) }}</span>
                    </div>
                    <div>
                        <span>{{ dictionary.payment_issuer }}</span>
                        <span>{{ formatNumber(payment_issuer) }}</span>
                    </div>
                    <div>
                        <span>{{ dictionary.payment_credit }}</span>
                        <span>{{ formatNumber(payment_credit) }}</span>
                    </div>
                </div>
            </div>
            <div class="component sum">
                <div data-icon="add_shopping_cart">{{ products_in_cart }} {{ dictionary.products_in_cart }}</div>
                <div data-icon="notification_important">{{ products_notify }} {{ dictionary.products_notify }}</div>
                <div data-icon="warning">{{ products_last_stock }} {{ dictionary.products_last_stock }}</div>
                <div data-icon="running_with_errors">{{ products_out_of_stock }} {{ dictionary.products_out_of_stock }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dictionary: {type: Object, required: true}
    },
    data(){
        return {
            i: 0,
            notifications: 0,
            new_orders: 22,
            new_messages: 0,
            checkouts: 10,
            modifications: 0,
            revenue: 7482,
            active_carts: 5,
            payment_credit_card: 2404,
            payment_apple_pay: 1000,
            payment_issuer: 1550,
            payment_credit: 540,
            products_in_cart: 38,
            products_notify: 21,
            products_last_stock: 5,
            products_out_of_stock: 0
        }
    },
    watch:{
        '$root.ready'(){
            setTimeout(() => window.requestAnimationFrame(this.animateValues.bind(this)), 1800);
        }
    },
    methods: {
        animateValues(){
            if(
                this.notifications >= 5 &&
                this.new_orders >= 28 &&
                this.new_messages >= 3 &&
                this.checkouts >= 18 &&
                this.modifications >= 4 &&
                this.revenue >= 7682 &&
                this.active_carts >= 12 &&
                this.payment_credit_card >= 2584 &&
                this.payment_apple_pay >= 1103 &&
                this.payment_issuer >= 1620 &&
                this.payment_credit >= 740 &&
                this.products_in_cart >= 58 &&
                this.products_notify >= 31 &&
                this.products_last_stock >= 12 &&
                this.products_out_of_stock >= 4
            ) return;

            if(this.notifications < 5) this.notifications++;
            if(this.new_orders < 28) this.new_orders++;
            if(this.new_messages < 3) this.new_messages++;
            if(this.checkouts < 18) this.checkouts++;
            if(this.modifications < 4) this.modifications++;
            if(this.revenue < 7682) this.revenue = this.revenue + 21;
            if(this.active_carts < 12) this.active_carts++;
            if(this.payment_credit_card < 2584) this.payment_credit_card = this.payment_credit_card + 19;
            if(this.payment_apple_pay < 1103) this.payment_apple_pay = this.payment_apple_pay + 19;
            if(this.payment_issuer < 1620) this.payment_issuer = this.payment_issuer + 19;
            if(this.payment_credit < 740) this.payment_credit = this.payment_credit + 19;
            if(this.products_in_cart < 58) this.products_in_cart++;
            if(this.products_notify < 31) this.products_notify++;
            if(this.products_last_stock < 12) this.products_last_stock++;
            if(this.products_out_of_stock < 4) this.products_out_of_stock++;

            this.i++;

            setTimeout(() => window.requestAnimationFrame(this.animateValues.bind(this)), this.i*50);
        },
		formatNumber(n, f = 0){
			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
    }
}
</script>
