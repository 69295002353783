import Vue from 'vue';

// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
// Vue.component('lottie-animation', LottieAnimation);
Vue.component('lottie-animation', require('./components/lottie-animation').default);

Vue.component('intro-components', require('./components/intro-components').default);
Vue.component('marketing', require('./components/marketing').default);
Vue.component('footer-form', require('./components/footer-form').default);

new Vue({
	data(){
		return {
			ready: false
		}
	},
	mounted(){
		setTimeout(() => this.ready = true, 1000);
	}
}).$mount('#app');
