<template>
    <div class="component" :class="active">
        <div class="assets"></div>
        <div class="box">
            <div class="title">{{ dictionary.title }}</div>
            <div class="ring">
                <div class="data">
                    <span :data-symbol="sources[active].symbol">{{counter}}</span>
                    <span>{{dictionary[active]}}</span>
                </div>
                <div v-for="(i, source) in sources" :key="source" class="source" :class="source"></div>
            </div>
            <div class="data">
                <div class="row"><i :style="`width: ${sources[active].bars[0]}%`"></i></div>
                <div class="row"><i :style="`width: ${sources[active].bars[1]}%`"></i></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        dictionary: { type: Object, required: true }
    },
    data(){
        return {
            active: 'google',
            counter: 2,
            sources: {
                google: {
                    count: 2,
                    symbol: '$',
                    bars: [64, 48]
                },
                youtube: {
                    count: 98,
                    bars: [52, 64]
                },
                facebook: {
                    count: 78,
                    symbol: '%',
                    bars: [94, 86]
                },
                instagram: {
                    count: 63,
                    bars: [62, 40]
                },
                whatsapp: {
                    count: 82,
                    bars: [68, 72]
                },
                newsletter: {
                    count: 13,
                    symbol: '%',
                    bars: [62, 86],
                },
                organic: {
                    count: 61,
                    bars: [75, 51]
                },
                carts: {
                    count: 58,
                    bars: [92, 83]
                },
            }
        }
    },
    watch:{
        active(){
            window.requestAnimationFrame(this.animate.bind(this));
        }
    },
    mounted(){
        let i = 0, sources = Object.keys(this.sources);

        setInterval(() => {
            i++;

            if(! sources[i]) i = 0;

            this.active = sources[i];
        }, 5000);
    },
    methods: {
        animate(){
            if(this.sources[this.active].count == this.counter) return;

            if(this.sources[this.active].count > this.counter)
                this.counter++;
            else
                this.counter--;

            window.requestAnimationFrame(this.animate.bind(this));
        }
    }
}
</script>
