<template>
    <div class="lottie-animation" @mouseenter="play"></div>
</template>
<script>
import lottie from 'lottie-web';

export default {
    props: {
        path: {type: String, required: true},
        rendrer: {type: String, default: 'svg'},
        loop: {type: Boolean, default: true},
        autoplay: {type: Boolean, default: true}
    },
    data(){
        return {
            controller: null
        }
    },
    mounted(){
        this.controller = lottie.loadAnimation({
            container: this.$el,
            path: this.path,
            rendrer: this.rendrer,
            loop: this.loop,
            autoplay: this.autoplay
        });

        setInterval(this.play.bind(this), 10000);
    },
    methods: {
        play(){
            if(! this.controller.isPaused) return;

            this.controller.goToAndPlay(0, true);
        }
    }
}
</script>
